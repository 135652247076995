// Functions to track clicks for GA4

'use strict';

// Record GA4 data for book direct banner clicks
function recordPromotionAnalytics(banner_name, banner_type, cta_text, event_type) {
  if (window.gtag) {
    gtag('event', event_type, {
      creative_name: banner_name,
      creative_slot: banner_type,
      promotion_name: cta_text,
    });
  }
}
